import React from "react"
import { Link } from "gatsby"

import Layout from "../components/layout"
import Image from "../components/image"
import SEO from "../components/seo"

const IndexPage = () => (
  <Layout>
    <SEO title="Home" />
    <p>After a series of extended holidays that were more like small adventures,
    going back to steady jobs and stable life stopped being 'enough'. I tell the
    normal people that we're having a midlife crisis but, actually, I think we're
    the ones who've seen the light!</p>

    <p>We flew to Bangkok on 12 Sept 2017. We have 5 months in Asia before meeting my
    parents in Singapore, and all flying to NZ. We'll spend some time with them
    (in a camper van!) before mounting our trusty steeds once again. We'll head
    home via a small part of Australia (where we plan to buy a car) and also some
    of Europe....we're flying to Bologna and riding back to the UK from there,
    somehow. Watch this space!</p>

    <p>We also have a Wordpress blog - unprofessionaladventurers.wordpress.com (and
    Facebook and instagram - @unprofessionaladventurers) for more general
    interest. Here I'll share thoughts that might be of use to future bike-
    adventurers. As I scoured CGOAB extensively myself, I thought I should return
    the favour!</p>

    <div style={{ maxWidth: `300px`, marginBottom: `1.45rem` }}>
      <Image />
    </div>
    <ul>
      <li><Link to="/map">Map</Link></li>
      <li><Link to="/journal/001">Journal</Link></li>
    </ul>
  </Layout>
)

export default IndexPage
